import { EventEmitter, Injectable, Inject, PLATFORM_ID } from '@angular/core';  
import { isPlatformBrowser } from '@angular/common';  
@Injectable()
export class Globals {
  readonly phoneTollFree: string = '888-643-3133';
  readonly phoneLocal: string = '949-502-0050';
  readonly phoneEmergency: string = '949-216-0438';
  readonly fax: string = '949-502-0048';

  readonly mainAddress: string = '19200 Von Karman Ave. Suite 150 Irvine, CA 92612';
  readonly derickKannAddress : string = '1250 S. Capital of Texas Hwy Bldg 3 Suite 400 Austin, TX 78746';

  readonly stockMarketYears: number = new Date().getFullYear() - 1928 ;
  readonly currentYear: number = new Date().getFullYear();
  readonly formADVUrl: string = 'https://adviserinfo.sec.gov/firm/summary/109143';
  
  readonly amazonIndexFundsTwelveStepBookUrl : string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors/dp/1955026939';
  readonly amazonIndexFundsTwelveStepAudioBookUrl : string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors/dp/B0CP6H8PWR';
  readonly amazonIndexFundsTwelveStepKindleUrl : string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors-ebook/dp/B0CL2Z23CT';
  readonly amazonUSFinancialHistoryBookUrl : string = 'https://www.amazon.com/Investing-U-S-Financial-History-Understanding/dp/B0CDJGG9RG';
  readonly amazonPrimeVideoIndexFundsMovieUrl: string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors/dp/B01M2YZUFN/';
  readonly amazonDVDIndexFundsMovieUrl: string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors/dp/0692696679/';
  readonly amazonInvestingKitUrl: string = 'https://www.amazon.com/dp/B07VKZTQTT/';
  readonly amazonGaltonBoardStockMarket : string = 'https://www.amazon.com/Galton-Board-Stock-Market-Edition/dp/B0C6C2YN8F/';
  readonly amazonGaltonBoardPascals : string = 'https://www.amazon.com/Galton-Board-with-Pascals-Triangle/dp/B0C6BJFD65/';
  readonly shopifyGaltonBoard: string = 'https://store.fourpines.com/collections/frontpage/products/galton-board-stock-market-edition';
  readonly shopifyPlayingCards: string = 'https://store.ifa.com/collections/frontpage/products/the-great-mirror-of-folly-playing-cards';
  readonly galtonboardmarketapp2024: string = 'https://apps.apple.com/us/app/galton-board-app/id6446604966';
  readonly googlePlayIndexFundsMovieUrl: string = 'https://play.google.com/store/movies/details/Index_Funds_The_12_Step_Recovery_Program_for_Activ?id=f2_tNwb6aO4';
  
  hideHeaderFooter = false;

  webp = true;
  printing:EventEmitter<boolean>=new EventEmitter();
  isLoggedIn =false;
  signInModalEvents:EventEmitter<any>=new EventEmitter();


  constructor(@Inject(PLATFORM_ID) private platformId: Object){
    //console.log("GLOBALS RUNS");    
    if (isPlatformBrowser(this.platformId)) {  
      this.webp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
  }



}